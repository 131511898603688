/** @jsx jsx */
import { jsx, css, withTheme } from "@emotion/react";
import styled from "@emotion/styled";

const icons = {
  arrowDown: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M20.7295405,7.31606213 C20.3808664,6.9441431 19.8148243,6.89813972 19.4129026,7.19035806 L19.3160621,7.27046605 L12,14 L4.68394438,7.27046605 C4.31202536,6.92179197 3.74419517,6.91236511 3.3619423,7.2298794 L3.27046605,7.31606213 C2.92179197,7.68798115 2.91236511,8.25581134 3.2298794,8.63806421 L3.31606213,8.72954046 L11.3160621,16.2295405 C11.6686655,16.5601061 12.2010107,16.5876532 12.583571,16.3121819 L12.6839444,16.2295405 L20.6839444,8.72954046 C21.0868567,8.3518102 21.1072707,7.7189744 20.7295405,7.31606213 Z"
        fill={color}
      ></path>
    </svg>
  ),
  arrowLeft: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M11.3871006,4.20970461 L11.2928932,4.29289322 L4.29289322,11.2928932 C4.25749917,11.3282873 4.22531295,11.3656744 4.19633458,11.4046934 L4.12467117,11.5159379 L4.07122549,11.628664 L4.03584514,11.734007 L4.00690716,11.8819045 L4,12 L4.00278786,12.0752385 L4.02024007,12.2007258 L4.04973809,12.3121425 L4.09367336,12.4232215 L4.15568797,12.5360882 L4.21968877,12.625449 L4.29289322,12.7071068 L11.2928932,19.7071068 L11.3871006,19.7902954 C11.7793918,20.0953203 12.3466228,20.0675907 12.7071068,19.7071068 C13.0675907,19.3466228 13.0953203,18.7793918 12.7902954,18.3871006 L12.7071068,18.2928932 L7.416,13 L19,13 C19.5522847,13 20,12.5522847 20,12 C20,11.4871642 19.6139598,11.0644928 19.1166211,11.0067277 L19,11 L7.415,11 L12.7071068,5.70710678 C13.0976311,5.31658249 13.0976311,4.68341751 12.7071068,4.29289322 C12.3466228,3.93240926 11.7793918,3.90467972 11.3871006,4.20970461 Z"
        fill={color}
      ></path>
    </svg>
  ),
  arrowRight: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M12.6128994,4.20970461 L12.7071068,4.29289322 L19.7071068,11.2928932 C19.7425008,11.3282873 19.774687,11.3656744 19.8036654,11.4046934 L19.8753288,11.5159379 L19.8753288,11.5159379 L19.9287745,11.628664 L19.9287745,11.628664 L19.9641549,11.734007 L19.9641549,11.734007 L19.9930928,11.8819045 L19.9930928,11.8819045 L20,12 L20,12 L19.9972121,12.0752385 L19.9972121,12.0752385 L19.9797599,12.2007258 L19.9797599,12.2007258 L19.9502619,12.3121425 L19.9502619,12.3121425 L19.9063266,12.4232215 L19.9063266,12.4232215 L19.844312,12.5360882 L19.844312,12.5360882 L19.7803112,12.625449 L19.7803112,12.625449 L19.7071068,12.7071068 L12.7071068,19.7071068 L12.6128994,19.7902954 C12.2206082,20.0953203 11.6533772,20.0675907 11.2928932,19.7071068 C10.9324093,19.3466228 10.9046797,18.7793918 11.2097046,18.3871006 L11.2928932,18.2928932 L16.584,13 L5,13 C4.44771525,13 4,12.5522847 4,12 C4,11.4871642 4.38604019,11.0644928 4.88337887,11.0067277 L5,11 L16.585,11 L11.2928932,5.70710678 C10.9023689,5.31658249 10.9023689,4.68341751 11.2928932,4.29289322 C11.6533772,3.93240926 12.2206082,3.90467972 12.6128994,4.20970461 Z"
        fill={color}
      ></path>
    </svg>
  ),
  arrowUp: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M20.7295405,16.1839411 C20.3808664,16.5558601 19.8148243,16.6018635 19.4129026,16.3096452 L19.3160621,16.2295372 L12,9.5 L4.68394438,16.2295372 C4.31202536,16.5782113 3.74419517,16.5876381 3.3619423,16.2701239 L3.27046605,16.1839411 C2.92179197,15.8120221 2.91236511,15.2441919 3.2298794,14.861939 L3.31606213,14.7704628 L11.3160621,7.2704628 C11.6686655,6.93989716 12.2010107,6.91235002 12.583571,7.18782139 L12.6839444,7.2704628 L20.6839444,14.7704628 C21.0868567,15.1481931 21.1072707,15.7810289 20.7295405,16.1839411 Z"
        fill={color}
      ></path>
    </svg>
  ),
  close: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M5.61289944,4.20970461 L5.70710678,4.29289322 L12,10.585 L18.2928932,4.29289322 L18.3871006,4.20970461 C18.7793918,3.90467972 19.3466228,3.93240926 19.7071068,4.29289322 C20.0675907,4.65337718 20.0953203,5.22060824 19.7902954,5.61289944 L19.7071068,5.70710678 L13.415,12 L19.7071068,18.2928932 C20.0976311,18.6834175 20.0976311,19.3165825 19.7071068,19.7071068 C19.3466228,20.0675907 18.7793918,20.0953203 18.3871006,19.7902954 L18.2928932,19.7071068 L12,13.415 L5.70710678,19.7071068 L5.61289944,19.7902954 C5.22060824,20.0953203 4.65337718,20.0675907 4.29289322,19.7071068 C3.93240926,19.3466228 3.90467972,18.7793918 4.20970461,18.3871006 L4.29289322,18.2928932 L10.585,12 L4.29289322,5.70710678 C3.90236893,5.31658249 3.90236893,4.68341751 4.29289322,4.29289322 C4.65337718,3.93240926 5.22060824,3.90467972 5.61289944,4.20970461 Z"
        fill={color}
      ></path>
    </svg>
  ),
  download: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M21,14 C21.5128358,14 21.9355072,14.3860402 21.9932723,14.8833789 L22,15 L22,18 C22,19.5976809 20.75108,20.9036609 19.1762728,20.9949073 L19,21 L5,21 C3.40231912,21 2.09633912,19.75108 2.00509269,18.1762728 L2,18 L2,15 C2,14.4477153 2.44771525,14 3,14 C3.51283584,14 3.93550716,14.3860402 3.99327227,14.8833789 L4,15 L4,18 C4,18.5128358 4.38604019,18.9355072 4.88337887,18.9932723 L5,19 L19,19 C19.5128358,19 19.9355072,18.6139598 19.9932723,18.1166211 L20,18 L20,15 C20,14.4477153 20.4477153,14 21,14 Z M12,2.5 C12.5128358,2.5 12.9355072,2.88604019 12.9932723,3.38337887 L13,3.5 L13,13.585 L17.2928932,9.29289322 C17.6533772,8.93240926 18.2206082,8.90467972 18.6128994,9.20970461 L18.7071068,9.29289322 C19.0675907,9.65337718 19.0953203,10.2206082 18.7902954,10.6128994 L18.7071068,10.7071068 L12.7071068,16.7071068 C12.6717127,16.7425008 12.6343256,16.774687 12.5953066,16.8036654 L12.4840621,16.8753288 L12.371336,16.9287745 L12.265993,16.9641549 L12.1174742,16.9931658 L12,17 L11.9247615,16.9972121 L11.7992742,16.9797599 L11.6878575,16.9502619 L11.5767785,16.9063266 L11.4792912,16.8540045 L11.3871006,16.7902954 L11.2928932,16.7071068 L5.29289322,10.7071068 C4.90236893,10.3165825 4.90236893,9.68341751 5.29289322,9.29289322 C5.65337718,8.93240926 6.22060824,8.90467972 6.61289944,9.20970461 L6.70710678,9.29289322 L11,13.585 L11,3.5 C11,2.94771525 11.4477153,2.5 12,2.5 Z"
        fill={color}
      ></path>
    </svg>
  ),
  locked: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M12,2 C14.8116315,2 17,4.10526614 17,7 L17,9 C18.1045695,9 19,9.8954305 19,11 L19,19 C19,20.1045695 18.1045695,21 17,21 L7,21 C5.8954305,21 5,20.1045695 5,19 L5,11 C5,9.9456382 5.81587779,9.08183488 6.85073766,9.00548574 L7,9 L7,7 C7,4.10526614 9.18836855,2 12,2 Z M12,12 C11.0335017,12 10.25,12.8058875 10.25,13.8 C10.25,14.366948 10.5048327,14.872676 10.9031099,15.2026133 L11.0056032,15.281369 L10.4624535,17.2351673 C10.4475633,17.2887722 10.4400092,17.3442501 10.4400092,17.4 C10.4400092,17.7058808 10.6625422,17.9583012 10.9501704,17.9953251 L11.0233425,18 L12.9766575,18 C13.0308588,18 13.0847956,17.99223 13.1369115,17.9769144 C13.4208677,17.893466 13.5952517,17.6074629 13.5541717,17.3149559 L13.5375465,17.2351673 L12.9949746,15.2809577 C13.4511778,14.9560812 13.75,14.4139345 13.75,13.8 C13.75,12.8058875 12.9664983,12 12,12 Z M12,4 C10,4 9,5.5 9,7 L9,9 L15,9 L15,7 C15,5.5 14,4 12,4 Z"
        fill={color}
      ></path>
    </svg>
  ),
  minus: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M6,11 L18,11 C18.5522847,11 19,11.4477153 19,12 C19,12.5522847 18.5522847,13 18,13 L6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4477153 5.44771525,11 6,11 Z"
        fill={color}
      ></path>
    </svg>
  ),
  plus: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M12,5 C12.5522847,5 13,5.44771525 13,6 L13,11 L18,11 C18.5522847,11 19,11.4477153 19,12 C19,12.5522847 18.5522847,13 18,13 L12.999,13 L13,18 C13,18.5522847 12.5522847,19 12,19 C11.4477153,19 11,18.5522847 11,18 L10.999,13 L6,13 C5.44771525,13 5,12.5522847 5,12 C5,11.4477153 5.44771525,11 6,11 L11,11 L11,6 C11,5.44771525 11.4477153,5 12,5 Z"
        fill={color}
      ></path>
    </svg>
  ),
  print: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M19,0 C19.5522847,0 20,0.44771525 20,1 L20,7 L21.1666667,7 C22.6596053,7 23.9045981,8.11244561 23.9947719,9.54733548 L24,9.71428571 L24,18.2857143 C24,19.7442389 22.8103012,20.910704 21.3377769,20.9951081 L21.1666667,21 L20,21 L20,22 C20,23.1045695 19.1045695,24 18,24 L6,24 C4.8954305,24 4,23.1045695 4,22 L4,21 L2.83333333,21 C1.3403947,21 0.0954018935,19.8875544 0.00522813107,18.4526645 L0,18.2857143 L0,9.71428571 C0,8.25576108 1.18969878,7.08929604 2.66222313,7.00489194 L2.83333333,7 L4,7 L4,1 C4,0.44771525 4.44771525,0 5,0 L19,0 Z M18,18 L6,18 L6,22 L18,22 L18,18 Z M5,12 C5.55228475,12 6,12.4477153 6,13 C6,13.5128358 5.61395981,13.9355072 5.11662113,13.9932723 L5,14 L3,14 C2.44771525,14 2,13.5522847 2,13 C2,12.4871642 2.38604019,12.0644928 2.88337887,12.0067277 L3,12 L5,12 Z M18,2 L6,2 L6,9 L18,9 L18,2 Z"
        fill={color}
      ></path>
    </svg>
  ),
  unlocked: ({ color, ...props }) => (
    <svg
      width="24px"
      height="24px"
      viewBox="0 0 24 24"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      {...props}
    >
      <path
        d="M15,9 L15,7 C15,5.5 14,4 12,4 C10.734472,4 9.86933435,4.60058537 9.40458689,5.42172733 L7.54941996,4.64181561 C8.37021996,3.03089489 10.032512,2 12,2 C14.8116315,2 17,4.10526614 17,7 L17,9 C18.1045695,9 19,9.8954305 19,11 L19,19 C19,20.1045695 18.1045695,21 17,21 L7,21 C5.8954305,21 5,20.1045695 5,19 L5,11 C5,9.9456382 5.81587779,9.08183488 6.85073766,9.00548574 L7,9 L15,9 Z M12,12 C11.0335017,12 10.25,12.8058875 10.25,13.8 C10.25,14.366948 10.5048327,14.872676 10.9031099,15.2026133 L11.0056032,15.281369 L10.4624535,17.2351673 C10.4475633,17.2887722 10.4400092,17.3442501 10.4400092,17.4 C10.4400092,17.7058808 10.6625422,17.9583012 10.9501704,17.9953251 L11.0233425,18 L12.9766575,18 C13.0308588,18 13.0847956,17.99223 13.1369115,17.9769144 C13.4208677,17.893466 13.5952517,17.6074629 13.5541717,17.3149559 L13.5375465,17.2351673 L12.9949746,15.2809577 C13.4511778,14.9560812 13.75,14.4139345 13.75,13.8 C13.75,12.8058875 12.9664983,12 12,12 Z"
        fill={color}
      ></path>
    </svg>
  ),
};

const Dot = ({ color, ...props }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={color}
      d="M43.7,26.9c-0.2,1-0.5,1.9-0.8,2.9l0.1-0.1l0.3-0.9l0.2-0.7C43.7,27.7,44.1,27,43.7,26.9z
  M46.9,25.8c0-0.1-0.2-0.6,0-0.6c0.2-0.2,0.1,1.2-0.2,1c-0.1,0.7-0.1,1.9-0.5,2.5c0.1-0.4,0.2-0.7,0.1-1.1
 c-0.3,0.6-0.2,1.2-0.4,1.7c-0.4,1.1-1,2-1.3,3c0.5-0.8,1.1-1.7,1.4-2.5c-0.1,0.3-0.2,0.6-0.2,1c-0.2-0.4-0.4,0.4-0.4,0.5l0-0.1
 l-0.2,0.5c0.4,0-0.4,1.8-0.4,2.3l-0.1,0.4L44.6,35l-0.1,0.3l-0.1,0.3c-0.1,0.3-0.3,0.6-0.4,0.9c-6.8,7.7-15.4,13.1-26.6,9.2l0.2,0
 l-0.4-0.1l-0.4-0.1l-0.5-0.1l-0.6-0.1c-0.7-0.1-1.7-0.5-2.1-0.8l-0.3-0.2l-0.2-0.1L12.9,44l-0.3-0.2l-0.2-0.1l-0.2-0.1l-0.2-0.1
 l-0.3-0.1c-1.8-1.5-3.9-2.6-5.5-4.6C1.2,34.4-0.2,25,1.8,17l0.1-0.4c0.2-0.6,0.4-1.2,0.6-1.8l0.1-0.4l0.1-0.3L3,13.8l0.1-0.3
 l0.1-0.3l0.1-0.3c0.1-0.1,0.2-0.4,0.3-0.5l0.1-0.1c0-0.1,0.2-0.2,0.2-0.3c0.7-1.8,2.2-2.9,3.5-4.3c0,0-0.1,0.1-0.1,0.1
 c12.4-13.7,38.7-5.5,39,13.6l0.1,0c-0.1,0.6,0.1,3-0.3,2.4c-0.1-0.2-0.1-1.1-0.3-0.8c-0.1-1.2-0.3-1.9-0.6-2.9L45,19.8l-0.1-0.5
 c-0.1-0.6-0.4-0.9-0.8-1.5c1.2,2.3,0.7,7.8,1,9c-0.1-0.3,0.7-3.1,0.1-2.3c0.1-0.6,0.2-0.6,0.4-0.4l0.1,0.1c0.2,0.3,0.6,0.6,0.7,0
 c0.2,0.3-0.2,0.6-0.1,0.8l0.1,0.3l0.2,0.6c0-0.2,0-0.5,0.1-0.6c-0.2-0.1-0.2-0.3-0.1-0.5c0,0.5,0.3,0.1,0.2,0.7
 c-0.1,0.8,0,0.8,0.1,0c0-0.3-0.1-0.7-0.2-1c0,0-0.1-0.2-0.1-0.1c0-0.1,0-0.2,0-0.3c-0.1,0.2,0,0.4,0,0.6l0-0.1c0-0.2,0-0.4,0-0.6
 C46.7,23.9,46.7,25.4,46.9,25.8z"
    />
  </svg>
);

const Outline = ({ color, ...props }) => (
  <svg
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 48 48"
    xmlSpace="preserve"
    {...props}
  >
    <path
      fill={color}
      d="M1.4,24.7c0-0.2-0.1-0.9,0-0.4C1,25.2,1.4,27,1.3,25C1.4,25.2,1.4,24.9,1.4,24.7
		c0,0.5-0.1,0.7,0,1.2c0.2-0.6,0.1-1.1,0.1-1.7c0.1,1.3,0.7-1.1,0.9,0.3c-0.6-0.1,0.5,3.4,0.5,4.1C3,29.2,3,29.9,3.5,30.2
		c0.2,0.1,0-0.5,0-0.4c-0.1,0.1-0.1,0.3-0.2-0.2c0.2,0.2,0.1-0.2,0.1-0.2C3,28,3.7,29.7,3.6,29.8c0,0.2,0.5,1.9,0.6,1.2
		c0,0,0.3,0.7,0.1,0.5C4.8,32.6,5.1,34,5.8,35c-0.3-0.4-1.3-3.1-0.9-2.1c1,3.1,2.5,4.3,4.4,6.4c-0.8-1.6-0.8-1.3,0.3-0.2l0,0
		c0.6,0.1,1.6,1.5,2.3,1.9c0.4,0.4,2.7,1.1,1.9,1.3l0,0c0.2,0.1,0.4,0.2,0.6,0.3c-0.3-0.3,0.2-0.1,0.4-0.1c0.1,0,0.7,0.2,0.4-0.1
		c0.7,0.2,1.5,1,2.4,1c0.4-0.2,1.6,0.1,2.4,0.4c0.7,0.4,1.1,0.1,1.7,0.2c-1.7-0.2-3.4-0.7-5.1-1.3c3.8,0.7,8.4,1.8,11.9,0.9
		c0.4-0.2,1.1-0.5,1.6-0.7l0.3-0.1l0.8-0.3c0.7-0.2,1.5-0.4,2.2-0.7c10.1-3.5,14.1-18.7,8-27.4c1.8,3-0.9-2.5-1.8-3.4l0.1,0.1
		l0.2,0.1c0.2,0.1,0.3,0.2,0.6,0.5c-0.9-1.1-1.8-2.4-2.9-3.4c0,0.1-0.2,0-0.2,0l-0.2-0.1l-0.6-0.4l0,0l0.2,0.1
		c-2.1-1.5-4.5-3-6.9-3.4l-0.4-0.1c-0.6-0.2-1.3-0.5-1.9-0.4c0,0-0.7-0.1-0.5,0C20.6,2.5,13.6,5.1,8.8,9.8l0-0.1
		c-3.8,3.4-6.5,9.1-7.1,14C-1.3,6,27-7.7,40.6,7.8c8.5,7.1,8.3,24.7,1,31c-1.7,2-3.8,3.4-5.9,4.8l-0.3,0.2l-0.6,0.4
		c-0.7,0.4-1.2,0.8-2.2,1l-0.4,0.1l-0.6,0.2l-0.4,0.1l-0.5,0.1l-0.3,0.1l0,0l0.2,0l-0.4,0.1c-11.1,3.3-19.4-1.6-26-9.6
		c-0.6-1.1-1.6-3.7-1.5-4.5l-0.2-0.5l0,0.1c0-0.1-0.3-0.9-0.4-0.5c0-0.3-0.1-0.7-0.2-1c0.3,0.9,0.8,1.8,1.2,2.6
		c-0.6-1.6-1.4-3.1-1.6-4.8c0.3,2.3-0.3-0.5-0.3-1.4c-0.4-0.1,0-2-0.1-0.4c0.1-0.3,0.1-1.9,0.3-1.8C1.4,24.3,1.4,24.5,1.4,24.7z"
    />
  </svg>
);

const Container = withTheme(styled.div`
  position: relative;
  background-color: transparent;
  border: 0;
  padding: 0;
  width: 32px;
  height: 32px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  ${({ theme, small, tiny }) =>
    tiny
      ? ""
      : theme.funcs.mediaquery(
          "xl",
          `
            width: ${small ? 46 : 60}px;
            height: ${small ? 46 : 60}px;
        `
        )}
  > svg {
    pointer-events: none;
  }
  ${() => `> svg path { transition: fill .15s linear;}`}
  > svg + svg {
    max-width: 75%;
    height: auto;
    ${({ theme, small }) =>
      theme.funcs.mediaquery("xl", `width: ${!small ? 35 : 24}px;`)}
  }
`);

const DotIcon = ({
  children,
  icon,
  outline,
  small = false,
  color = "#fff",
  bgColor = "#002C78",
  ...props
}) => {
  const Background = outline ? Outline : Dot;
  const Foreground = icons[icon] || null;

  return (
    <Container small={small ? 1 : 0} {...props}>
      <Background
        color={bgColor}
        aria-hidden="true"
        css={css`
          position: absolute;
          left: 0;
          top: 0;
          width: 100%;
          height: 100%;
        `}
      />
      {!!Foreground && (
        <Foreground
          color={color}
          css={css`
            position: relative;
          `}
        />
      )}
      {children}
    </Container>
  );
};

export default DotIcon;
